<template>
  <div>
    <div class="title">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>联系我们</p>
    </div>
    <div>
      <div class="rgjcimg">
        <img src="../assets/img/bannerrg.jpg" />
      </div>
    </div>
    <div>
      <div class="rgjc_lxwm">
        <div style="padding-left:0.8rem;">联系我们</div>
        <div class="rgjc_hx"></div>
      </div>
      <div class="rgjc_model">
        <div>深圳智汇教育有限公司</div>
        <div>客服微信：LwCheck</div>
        <div>联系电话：400-823-8869</div>
        <div>投诉建议：kefu@lianwen.com</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.rgjcimg img {
  width: 100%;
  height: 3rem;
}
.rgjc_lxwm {
  text-align: left;
  margin: 0.5rem 0.6rem;
  font-weight: bold;
  font-size: 0.46rem;
}
.rgjc_hx {
  margin-top: 0.3rem;
  border-bottom: 1px solid #ccc;
}
.rgjc_model {
  margin-left: 16%;
  margin-top: 0.7rem;
}
.rgjc_model div {
  margin-bottom: 0.43rem;
  font-size: 0.37rem;
}
</style>